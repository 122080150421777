import React from "react"
import Layout from "../../components/layout/layout"
import SmallHero from "../../components/layout/smallHero"
import imgCover from "../../img/grands_pieds/cover_course_grands_pied.png"
import parcours from "../../img/grands_pieds/plan_parcours.png"

// Sponsor logo
import SportAventureLogo from "../../img/sponsors/sponsor_sport_aventure.png"
import TopScoreDiffusionLogo from "../../img/sponsors/sponsor_top_score_diffusion.png"
import BossBeerLogo from "../../img/sponsors/sponsor_boss_beer.png"
import TpfLogo from "../../img/sponsors/sponsor_tpf.png"
import MigrosLogo from "../../img/sponsors/sponsor_migros.jpg"
import RaiffeisenLogo from "../../img/sponsors/sponsor_raiffeisen.svg"
import SinefLogo from "../../img/sponsors/sponsor_sinef.jpg"
import HeadLogo from "../../img/sponsors/sponsor_head.png"
import FromagerieMeziereLogo from "../../img/sponsors/sponsor_meziere.svg"
import TrilogieSportLogo from "../../img/sponsors/sponsor_trilogie_sport.jpeg"
import ManorLogo from "../../img/sponsors/sponsor_manor.svg"
import EspaceLogisLogo from "../../img/sponsors/sponsor_espace_logis.jpeg"
import Boulevard39Logo from "../../img/sponsors/sponsor_boulevard39.svg"
import BindellaLogo from "../../img/sponsors/sponsor_bindella.jpg"

// twint
import Twint from "../../img/twint.png"

import "../mystyles.scss"

// Picto TPF
import l8_picto from "../../img/grands_pieds/Picto_L008.jpg"
import five42_picto from "../../img/grands_pieds/542_picto.png"
import n2_picto from "../../img/grands_pieds/n2_picto.png"

import { Helmet } from "react-helmet"

const sponsors_main = [
  { href: "https://www.tpf.ch/fr/", src: TpfLogo, alt: "Tpf logo" },
  {
    href: "https://www.raiffeisen.ch/",
    src: RaiffeisenLogo,
    alt: "Raiffeisen logo",
  },
  {
    href: "https://www.sports-aventures.ch/",
    src: SportAventureLogo,
    alt: "sports-aventures logo",
  },
]
const sponsors = [
  {
    href: "http://shop.topscorediffusion.ch/welcome",
    src: TopScoreDiffusionLogo,
    alt: "Top Score Diffusion Logo",
  },
  {
    href: "https://www.bossbeer.ch/",
    src: BossBeerLogo,
    alt: "Boss Beer Logo",
  },
  {
    href: "https://www.migros-engagement.ch/en/culture-percentage",
    src: MigrosLogo,
    alt: "Migros Logo",
  },
  { href: "https://www.sinef.ch/", src: SinefLogo, alt: "Sinef Logo" },
  { href: "https://www.head.com/de_CH/home", src: HeadLogo, alt: "Head Logo" },
  {
    href: "https://www.trilogiesport.ch/",
    src: TrilogieSportLogo,
    alt: "Trilogie Sport Logo",
  },
  {
    href: "https://www.fromagerie-mezieres.ch/fr",
    src: FromagerieMeziereLogo,
    alt: "Fromagerie Mezieres Logo",
  },
  { href: "https://www.manor.ch/", src: ManorLogo, alt: "Manor Logo" },
  {
    href: "https://espace-logis.ch/",
    src: EspaceLogisLogo,
    alt: "Espace Logis Logo",
  },
  {
    href: "https://www.leboulevard.ch/fr",
    src: Boulevard39Logo,
    alt: "Boulevard 39 Logo",
  },
  {
    href: "https://www.bindella.ch/gastronomie/bindella-fribourg",
    src: BindellaLogo,
    alt: "Bindella Logo",
  },
]

export default function CourseGrandsPieds() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Grands Pieds</title>
      </Helmet>
      <SmallHero title="Course des Grands Pieds" color="is-warning" />
      <article className="section">
        <div className="container">
          <h1 className="title">Résultats </h1>
          Vous pouvez consulter les résultats en suivant ce lien :{" "}
          <a href="https://live.l-chrono.com/resultats/course-des-grands-pieds-2022">
            https://live.l-chrono.com/resultats/course-des-grands-pieds-2022
          </a>
          <br />
          <br />
          <div className="columns is-vcentered">
            <div className="column">
              <p>
                Salut à toi, grand sportif et grande sportive! <br /> <br />
                La Team Kangourou te souhaite la bienvenue et se réjouit de te
                voir en forme à la{" "}
                <span className="has-text-weight-bold">
                  Course des Grands Pieds
                </span>{" "}
                ! En plus d’être un événement sportif, cette manifestation se
                veut familiale, sans prise de tête et avec pour but principal :
                que chaque participant et participante prenne du plaisir !{" "}
                <br /> Que tu coures en club, que tu coures par passion ou que
                tu coures occasionnellement, tu trouveras chaussure à ton pied
                parmi les différents parcours du{" "}
                <span className="has-text-weight-bold">
                  21 mai 2022 à Corminboeuf
                </span>
                ! <br /> Alors n’attends pas et inscris toi !
                <br /> <br />
                Le comité d’organisation
              </p>
            </div>
            <div className="column">
              <figure className="image">
                <img className="has-ratio" src={imgCover} alt="" />
              </figure>
            </div>
          </div>
          <br />
          <br />
          <h1 className="title">Évènement </h1>
          <div className="content">
            <ul>
              <li>
                Où ?{" "}
                <span className="has-text-weight-bold">
                  Route du Centre 23, 1720 Corminboeuf
                </span>
              </li>
              <li>
                Quand ? Le{" "}
                <span className="has-text-weight-bold">21 mai 2022</span> dès
                16h (cf. voir programme)
              </li>
              <li>
                Quoi ?{" "}
                <span className="has-text-weight-bold">Trois parcours </span> à
                choix
              </li>
            </ul>
          </div>
          <br />
          <br />
          <h1 className="title">Comment s’y rendre ? Transports publics</h1>
          <div className="">
            <p>
              Bus : lignes{" "}
              <img
                className="inline-block object-contain h-5 w-5 align-text-bottom"
                src={l8_picto}
                alt=""
              />{" "}
              ou{" "}
              <img
                className="inline-block object-contain h-5 w-10 align-text-bottom"
                src={five42_picto}
                alt=""
              />{" "}
            </p>
            <p>Arrêts : Corminboeuf, village</p>
            <br />
            De nombreux bus pourront vous ramener sur Fribourg. Un des derniers
            bus (ligne
            <img
              className="inline-block object-contain h-5 w-10 align-text-bottom"
              src={n2_picto}
              alt=""
            />
            ) part à 01h14 de Corminboeuf, village.  
            <br />
            <br />
            Horaire et itinéraire sur{" "}
            <a href="https://www.tpf.ch/fr/">tpf.ch</a>
            <br />
            <br />
            <p>
              Votre confirmation de paiement fait office de titre de transport
              en zones 10 et 11 le jour de la course.
            </p>
          </div>
          <br />
          <br />
          <article className="bg-[#FFA500] text-white p-4 rounded">
            <h1 className="title">
              <span className="text-white">Inscriptions</span>
            </h1>
            <h2 className="title is-5">
              <span className="text-white">Inscription à la course</span>
            </h2>
            <p>
              Les inscriptions pour les coureurs se font via le formulaire
              suivant :{" "}
              <a
                href="https://forms.gle/zDY8GLPomNb43YVj9"
                className="font-bold text-blue-900"
              >
                Formulaire d'inscription
              </a>
              <br />
              Votre inscription sera validée lors de la réception du paiement
              qui peut s'effectuer dans les 30 jours après l'inscription et au
              plus tard le 19 mai.
            </p>
          </article>
          <span className="font-extrabold">
            Inscription en ligne possible jusqu’au 20 mai 22h00. Inscription sur
            place possible le jour de la course.
          </span>
          <br />
          <br />
          <h2 className="title is-5">
            Inscription et infomations pour les bénévoles
          </h2>
          <p>
            <a href="/course_grands_pieds/benevoles">
              Inscription et infomations pour les bénévoles
            </a>
          </p>
          <br />
          <br />
          <h1 className="title">Programme</h1>
          <div className="content">
            <ul>
              <li>Dès 16h00 : Récupérer les dossards</li>
              <li>17h30 : Départ des 7.5km et 15km </li>
              <li>19h : Départ « Run to Beer » </li>
              <li>Dès 19h : Repas “Pasta Party”</li>
              <li>20h : Dernière arrivée</li>
              <li>20h30 : Distribution des prix</li>
              <li>
                21h30 : Soirée dans la salle polyvalente avec bar et musique
              </li>
              <li>1h : Fin de la manifestation</li>
            </ul>
          </div>
          <br />
          <div className="columns is-vcentered">
            <div className="column">
              <h1 className="title">Parcours</h1>
              <h2 className="title is-5">7.5km</h2>
              <p>
                Cette distance, adaptée pour un grand nombre d’amateurs, permet
                à chacun de venir tester son niveau dans une course officielle.
                Rapide, elle risque d’offrir un très beau combat aux avants
                postes dans la bagarre pour la première place.
              </p>
              <br />
              <h2 className="title is-5">15km</h2>
              <p>
                Cette belle forêt de Moncor fait de l’œil à de nombreux
                coureurs. Aucuns soucis, cette course est l’occasion de faire
                deux fois la boucle qui passe par la forêt. En effet, en
                parcourant 15km, le participant peut goûter l’effort sur une
                longue durée, entre 50 minutes pour les plus rapides et 2h30
                pour les plus téméraires !
              </p>
              <br />
              <h2 className="title is-5">Run to Beer</h2>
              <p>
                Le coureur qui aime l’apéro et le sport va adorer ce format de
                course ! Cette dernière n’est pas très longue, seulement 5 km.
                Mais chaque binôme, défini lors de l’inscription, doit goûter
                une bière différente d’une brasserie locale à chaque kilomètre.
                Il faut comprendre que cette course ne comporte rien de
                traditionnel : Chaque participant doit être déguisé et son
                inscription doit contenir un court texte de maximum 300 mots
                pour expliquer en quoi ce duo veut participer à la course ! Les
                places sont très limitées, donc ne tarde pas !
              </p>
            </div>
            <div className="column flex flex-col">
              <figure className="image">
                <img
                  className="has-ratio"
                  src={parcours}
                  alt="Plan du parcours"
                />
              </figure>
              <div className="text-center">
                Distance: 7.7km // Dénivelé: 120 mètres
              </div>
            </div>
          </div>
          <br />
          <br />
          <h1 className="title">Prix</h1>
          <div>
            <table className="table">
              <thead>
                <tr>
                  <th className="w-96">Inscription basique</th>
                  <th>7.5 km</th>
                  <th>15 km</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <ol className="list-decimal ml-8">
                      <li>Dossard</li>
                      <li>Chronométrage officiel</li>
                      <li>Tickets TPF zones 10/11</li>
                      <li>Petite attention offerte par Manor</li>
                    </ol>
                  </td>
                  <td className="font-bold text-2xl">23 CHF</td>
                  <td className="font-bold text-2xl">25 CHF</td>
                </tr>
              </tbody>
            </table>

            <table className="table">
              <thead>
                <tr>
                  <th className="w-96">Inscription complète</th>
                  <th>7.5 km</th>
                  <th>15 km</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <ol className="list-decimal ml-8">
                      <li>Dossard</li>
                      <li>Chronométrage officel</li>
                      <li>Tickets TPF zone 10/11</li>
                      <li>Petite attention offerte par Manor</li>
                      <li className="text-orange-500">
                        Grand linge de bain de la marque Head
                      </li>
                      <li className="text-orange-500">
                        Une bière de notre partenaire Boss’Beer
                      </li>
                    </ol>
                  </td>
                  <td className="font-bold text-2xl">35 CHF</td>
                  <td className="font-bold text-2xl">40 CHF</td>
                </tr>
              </tbody>
            </table>

            <table className="table">
              <thead>
                <tr>
                  <th className="w-96">Run to Beer</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <ol className="list-decimal ml-8">
                      <li>Dossard</li>
                      <li>Chronométrage officel</li>
                      <li>Tickets TPF zone 10/11</li>
                      <li>Petite attention offerte par Manor</li>
                      <li>Grand linge de bain de la marque Head</li>
                      <li className="text-red-500">
                        5 bières de notre partenaire Boss’Beer
                      </li>
                    </ol>
                  </td>
                  <td colSpan={2} className="font-bold text-2xl">
                    45 CHF
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <br />
          <br />
          <h2 className="subtitle">Informations pour le paiement</h2>
          <p>
            Adresse postale : Rue du Nord 5 1700 Fribourg [Marc-Antoine
            Levionnois] <br />
            Motif : NOM, Prénom Course des Grands Pieds <br />
            Coordonnée I-BAN : CH26 0076 8300 1577 4400 2 <br />
            Compte postal : 19-49-3
          </p>
          <br />
          <h2 className="subtitle">Ou via twint :</h2>
          <figure>
            <img src={Twint} alt={"Twint"} className="object-contain" />
          </figure>
          <br />
          <br />
          {/* <h1 className="title">Mesures COVID</h1>
          <p>
            Actuellement, dû aux mesures COVID, la manifestation comprendra au
            total 500 personnes (coureurs et bénévoles compris). Un complément
            d'informations concernant la situation sanitaire sera ajouté
            ultérieurement.
          </p>
          <br />
          <br /> */}
          <h1 className="title">Organisation et contacts</h1>
          <p>
            Organisateur et organisatrices : Marc-Antoine Levionnois, Caroline
            Pittet et Mélanie Pereira Carvalho <br />
            Contactez-nous à l’adresse mail suivante :{" "}
            <a href="mailto: lacoursedesgrandspieds@gmail.com">
              lacoursedesgrandspieds@gmail.com
            </a>
          </p>
          <br />
          <br />
          <h1 className="title">Sponsors et Partenaires</h1>
          <nav className="level flex flex-wrap flex-col lg:flex-row space-y-8">
            {sponsors_main.map(({ alt, href, src }, idx) => (
              <div
                className="basis-1/3 flex justify-center content-center m-0"
                key={idx}
              >
                <figure>
                  <a href={href}>
                    <img
                      src={src}
                      alt={alt}
                      className="object-contain h-28 w-48 lg:h-48 lg:w-60"
                    />
                  </a>
                </figure>
              </div>
            ))}
          </nav>
          <br />
          <nav className="flex flex-wrap flex-col lg:flex-row space-y-8 justify-center content-center">
            {sponsors.map(({ alt, href, src }, idx) => (
              <div
                className="basis-1/4 flex justify-center content-center m-0"
                key={idx}
              >
                <figure>
                  <a href={href}>
                    <img
                      src={src}
                      alt={alt}
                      className="object-contain h-28 w-48"
                    />
                  </a>
                </figure>
              </div>
            ))}
          </nav>
        </div>
      </article>
    </Layout>
  )
}
